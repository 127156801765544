import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "../ui/DatePicker";
import { Add, Delete } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { account, common, confirm } from "../messages";
import { useBmapi } from "../utils/bmapi-context";
import { format } from "date-fns";
import { getErrorMessageString } from "../utils/errors";
import Confirm from "../ui/Confirm";

export default function EOrderClosingDates() {
  const getEmptyObj = (arr) => {
    return !arr || arr.length === 0 ? [{ from: null, to: null }] : arr;
  };

  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [save, setSave] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [periodToDelete, setPeriodToDelete] = useState(null);
  const businessID = bmapi.getUserInfo().business.id;

  const [period, setPeriod] = useState({
    date: getEmptyObj(null), // closing dates
  });

  useEffect(() => {
    loadClosingDates();
  }, [bmapi, loadClosingDates]);

  const handleDates = useCallback(
    (label, index) => (e) => {
      const newPeriod = { ...period };
      if (label === "from") {
        newPeriod.date[index][label] = e;
        newPeriod.date[index]["to"] = e;
      } else newPeriod.date[index][label] = e;
      setPeriod(newPeriod);
      setSave(true);
    },
    [period]
  );

  const handlePeriods = useCallback(
    (index, action) => () => {
      let newPeriod = { ...period };
      if (action) {
        newPeriod.date.splice(index + 1, 0, {
          from: null,
          to: null,
        });
      } else {
        newPeriod.date.splice(index, 1);
        if (!newPeriod.date.length) {
          newPeriod.date.push({ from: null, to: null });
        }
      }
      setPeriod(newPeriod);
      setSave(true);
      setShowDeleteAlert(false);
      setPeriodToDelete(null);
    },
    [period]
  );

  const fixPeriod = (d) => {
    return new Date(
      d.substring(0, 4) + "/" + d.substring(4, 6) + "/" + d.substring(6)
    );
  };

  const loadClosingDates = useCallback(() => {
    bmapi.getBusiness(businessID).then((resp) => {
      let closingDates = resp.closing_dates;
      if (closingDates) {
        let newDate = [];
        for (let i = 0; i < closingDates.length; i++) {
          let newObj = {
            from:
              closingDates[i].length === 8
                ? fixPeriod(closingDates[i])
                : fixPeriod(closingDates[i].substring(0, 8)),
            to:
              closingDates[i].length === 8
                ? fixPeriod(closingDates[i])
                : fixPeriod(closingDates[i].substring(9, 17)),
          };
          newDate.push(newObj);
        }
        let newPeriod = { ...period, date: newDate };
        setPeriod(newPeriod);
      }
    });
  }, [bmapi, period, businessID]);

  const checkSameDate = (date) => {
    const from = format(date.from, "yyyyMMdd");
    const to = format(date.to, "yyyyMMdd");
    if (from === to) {
      return from;
    } else return from + "-" + to;
  };

  const confirmDates = (event) => {
    event.preventDefault();
    for (let i = 0; i < period.date.length; i++) {
      if (period.date.length > 1 && period.date.find((p) => p.from === null)) {
        notifyError("Il periodo inserito non è valido o è vuoto.");
        return;
      }
    }
    let newDates = {};
    if (period.date[0].from === null) {
      newDates.dates = [];
    } else {
      newDates.dates = period.date.map((date) => checkSameDate(date));
    }
    bmapi
      .createClosingDates(newDates)
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
        setSave(false);
      })
      .then(() => {
        loadClosingDates();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  return (
    <>
      <form onSubmit={confirmDates}>
        <Box mt={2}>
          <Card style={{ marginTop: 20 }}>
            <CardContent>
              {period.date.map((date, i) => (
                <React.Fragment key={i}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <DatePicker
                        margin="dense"
                        label={intl.formatMessage(common.from)}
                        inputFormat="dd/MM/yyyy"
                        value={date.from}
                        onChange={handleDates("from", i)}
                        format="dd/MM/yy"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DatePicker
                        margin="dense"
                        label={intl.formatMessage(common.to)}
                        inputFormat="dd/MM/yyyy"
                        value={date.to}
                        onChange={handleDates("to", i)}
                        format="dd/MM/yy"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={handlePeriods(i, 1)}
                        style={{ margin: "24px 12px" }}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        //onClick={handlePeriods(i, 0)}
                        onClick={() => {
                          setShowDeleteAlert(true), setPeriodToDelete(i);
                        }}
                        style={{ margin: "24px 12px" }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {/*<List margin="normal">
                  <DatePicker
                    label={intl.formatMessage(common.from)}
                    inputFormat="dd/MM/yyyy"
                    value={date.from}
                    onChange={handleDates("from", i)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label={intl.formatMessage(common.to)}
                    inputFormat="dd/MM/yyyy"
                    value={date.to}
                    onChange={handleDates("to", i)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={handlePeriods(i, 1)}>
                      <Add />
                    </IconButton>
                    <IconButton onClick={handlePeriods(i, 0)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </List> */}
                </React.Fragment>
              ))}
            </CardContent>
          </Card>
          {save && (
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary">
                {intl.formatMessage(common.save)}
              </Button>
            </Box>
          )}
        </Box>
      </form>

      <Confirm
        open={showDeleteAlert}
        onConfirm={handlePeriods(periodToDelete, 0)}
        onCancel={() => {
          setShowDeleteAlert(false);
          setPeriodToDelete(null);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />
    </>
  );
}
