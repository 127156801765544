import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";

import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Adjust as AdjustIcon } from "@material-ui/icons";

import CampaignPerformance from "./CampaignPerformance";
import CreateCampaignButton from "./CreateCampaignButton";
import CampaignActions from "../components/CampaignActions";
import { useBmapi } from "../utils/bmapi-context";
import { IconsMap } from "../utils/campaigns";
import { EVENT_STATUS, FEATURES, MANAGER_ROUTES } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import styles from "../utils/styles";
import { common } from "../messages";

function CampaignElement({ campaign, messages, events, isPrize, onUpdate }) {
  const history = useHistory();
  const theme = useTheme();
  const intl = useIntl();
  const classes = styles.useStyles();
  const { bmapi, notifyError } = useBmapi();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [terms, setTerms] = useState(false);

  function handleCloseClick() {
    setExpanded(false);
  }

  function loadPerformance() {
    setLoading(true);
    setPerformance(false);

    return bmapi
      .getCampaignDetails(campaign.campaign_id)
      .then(({ use_terms, performance }) => {
        setPerformance(performance);
        setTerms(use_terms);
        setLoading(false);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }

  function handleExpandClick() {
    loadPerformance().then(() => setExpanded(true));
  }

  const CampaignIcon = IconsMap[campaign.type];

  const dateInfo = () => {
    if (campaign.isExpired) {
      return intl.formatMessage(
        {
          id: "component.campaigns.expired",
          defaultMessage: "Scaduta il {expirationDate, date, medium}",
        },
        { expirationDate: new Date(campaign.expiration_date) }
      );
    } else if (campaign.isFuture) {
      return intl.formatMessage(
        {
          id: "component.campaigns.startDate",
          defaultMessage: "Inizio campagna: {startDate, date, medium}",
        },
        { startDate: new Date(campaign.start_date) }
      );
    }
    return intl.formatMessage(
      {
        id: "component.campaigns.expiration",
        defaultMessage: "Scadenza: {expirationDate, date, medium}",
      },
      { expirationDate: new Date(campaign.expiration_date) }
    );
  };

  const goToCampaign = () => {
    history.push(
      MANAGER_ROUTES.CAMPAIGN.replace(":campaignId", campaign.campaign_id)
    );
  };

  const goToMessagesCampaign = () => {
    window.location = MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(
      ":campaignId",
      campaign.campaign_id
    );
    history.push(
      MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(
        ":campaignId",
        campaign.campaign_id
      )
    );
  };

  return (
    <React.Fragment>
      <ListItem
        button
        onClick={messages != undefined ? goToMessagesCampaign : goToCampaign}
      >
        <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
          <Badge
            color="error"
            overlap="circle"
            badgeContent=""
            invisible={true}
          >
            <Avatar
              src={campaign.avatar_url || ""}
              className={classNames(classes.cardIcon, classes.cardIconLarge, {
                [classes.disabled]: campaign.toSign || campaign.isExpired,
              })}
            >
              {CampaignIcon ? <CampaignIcon /> : <AdjustIcon />}
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                variant="caption"
                display="block"
                color={campaign.loop_campaign ? "primary" : "inherit"}
              >
                {campaign.business_owner_name}
              </Typography>
              <Typography display="block" gutterBottom>
                {campaign.name}{" "}
                {isPrize && (
                  <Chip
                    component="span"
                    label={intl.formatMessage({
                      id: "common.premio",
                      defaultMessage: "Premio",
                    })}
                    size="small"
                    className={classes.demoChip}
                  />
                )}{" "}
                {messages != undefined ? (
                  <Typography
                    display="block"
                    color={"blue"}
                    style={{ position: "absolute", right: "30px" }}
                  >
                    Messaggi:{messages.length}
                  </Typography>
                ) : (
                  ""
                )}
                {events.some((e) => e.status === EVENT_STATUS.STARTED) && (
                  <Chip
                    component="span"
                    label={intl.formatMessage(common.eventRunning)}
                    size="small"
                    className={classes.demoChip}
                    style={{ backgroundColor: theme.palette.error.main }}
                  />
                )}
                {campaign.demo && (
                  <Chip
                    component="span"
                    label={intl.formatMessage({
                      id: "common.demo",
                      defaultMessage: "Demo",
                    })}
                    size="small"
                    className={classes.demoChip}
                  />
                )}
              </Typography>
            </React.Fragment>
          }
          secondary={dateInfo()}
        />
        <ListItemSecondaryAction>
          {messages == undefined && (
            <CampaignActions
              campaign={campaign}
              events={events}
              onUpdate={onUpdate}
              visibleInfo={expanded}
              isPrize={isPrize}
              loading={loading}
              performance={performance}
              loadPerformance={loadPerformance}
              hideInfo={handleCloseClick}
              showInfo={handleExpandClick}
              edge="end"
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {campaign.toSign ? null : (
            <ListItem>
              <ListItemText
                classes={{ inset: classes.listItemInset }}
                inset
                primary={
                  <CampaignPerformance
                    campaign={campaign}
                    owner={campaign.business_id === campaign.business_owner_id}
                    performance={performance}
                    terms={terms}
                  />
                }
              />
            </ListItem>
          )}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

function isPrize(campaign, prizes) {
  return prizes.some((p) => p.prize_id === campaign.campaign_id);
}

function CampaignsList({ campaigns, messages, events, onUpdate, prizes }) {
  return campaigns.length ? (
    <Card>
      <List>
        {campaigns.map((campaign, i) => (
          <React.Fragment key={campaign.id}>
            {i !== 0 && <Divider variant="inset" component="li" />}
            <CampaignElement
              key={campaign.id}
              campaign={campaign}
              messages={
                messages.length > 0
                  ? messages.filter(
                      (x) => x.message.campaign_id == campaign.campaign_id
                    )
                  : undefined
              }
              onUpdate={onUpdate}
              isPrize={isPrize(campaign, prizes)}
              events={events.filter((e) =>
                (e.campaign_ids || []).includes(campaign.campaign_id)
              )}
            />
          </React.Fragment>
        ))}
      </List>
    </Card>
  ) : (
    <Card>
      <CardContent style={{ padding: 16 }}>
        <FormattedMessage
          id="component.campaigns.noCampaign"
          defaultMessage="Nessuna campagna presente."
        />
      </CardContent>
    </Card>
  );
}

export default function CampaignsTable({
  campaigns = [],
  messages = [],
  loadCampaigns,
}) {
  const { filter = "active" } = useParams();
  const { bmapi, businessId } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (businessId) bmapi.getBusinessEvents().then(setEvents);
  }, [bmapi, businessId]);

  const handleUpdate = useCallback(() => {
    bmapi.getBusinessEvents().then(setEvents);
    return loadCampaigns();
  }, [bmapi, loadCampaigns]);

  const campaignFilters = {
    active: (c) => c.isActive,
    next: (c) => c.isFuture,
    availables: (c) => c.isAvailable,
    expired: (c) => c.isExpired,
  };

  const prizes = campaigns
    .map((c) => c.campaign_data.rules.prizes)
    .filter((p) => !!p)
    .flat();

  return campaigns.length ? (
    <React.Fragment>
      <Box mb={2}>
        <Tabs
          value={filter}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          onChange={(_, v) =>
            history.replace(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?", v))
          }
        >
          <Tab
            label={intl.formatMessage({
              id: "common.actives",
              defaultMessage: "Attive",
            })}
            value="active"
            style={{ minWidth: 0 }}
          />
          <Tab
            label={intl.formatMessage({
              id: "common.next",
              defaultMessage: "Prossime",
            })}
            value="next"
            style={{ minWidth: 0 }}
          />
          {(!bmapi.subscription ||
            bmapi.subscription.plan_limits.loop_campaigns) && (
            <Tab
              label={intl.formatMessage({
                id: "common.availables",
                defaultMessage: "Disponibili",
              })}
              value="availables"
              style={{ minWidth: 0 }}
            />
          )}
          <Tab
            label={intl.formatMessage({
              id: "common.expired",
              defaultMessage: "Concluse",
            })}
            value="expired"
            style={{ minWidth: 0 }}
          />
        </Tabs>
      </Box>
      <CampaignsList
        campaigns={campaigns.filter(campaignFilters[filter])}
        messages={messages}
        onUpdate={handleUpdate}
        events={events || []}
        prizes={prizes}
      />
    </React.Fragment>
  ) : bmapi.can(FEATURES.MANAGE_CAMPAIGN) ? (
    <CreateCampaignButton
      trigger={(onClick) => (
        <Button onClick={onClick} variant="contained">
          <FormattedMessage
            id="component.campaigns.createFirst"
            defaultMessage="Crea la tua prima campagna"
          />
        </Button>
      )}
    />
  ) : (
    <Typography gutterBottom>
      <FormattedMessage
        id="component.campaigns.noCampaign"
        defaultMessage="Nessuna campagna presente."
      />
    </Typography>
  );
}
