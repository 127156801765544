import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Container, Menu, MenuItem, TextField } from "@material-ui/core";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import MessageCampaingCreateForm from "../../ui/forms/MessageCampaingCreateForm";
import { MANAGER_ROUTES } from "../../utils/constants";
import FormSection from "../../ui/forms/input/FormSection";
import { getErrorMessageString } from "../../utils/errors";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

function byBusinessName(a, b) {
  return a.business_name.localeCompare(b.business_name);
}

function initValues() {
  return { campaign: null, title: "", body: "" };
}

export default function Campaigns() {
  const {
    bmapi,
    businessId,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();
  const history = useHistory();
  const { campaigns, loadCampaigns } = useCampaigns();
  const [currentStore, setCurrentStore] = useState(0);
  const [values, setValues] = useState(initValues());
  const [anchorEl, setAnchorEl] = useState(null);
  const [stores, setStores] = useState(null);
  const intl = useIntl();

  const selectStore = (id) => {
    setCurrentStore(id);
    setAnchorEl(null);
  };

  useEffect(() => {
    !campaigns ? startLoading() : stopLoading();
  }, [campaigns, startLoading, stopLoading]);

  useEffect(() => {
    if (campaigns) {
      campaigns.push({
        name: "Nessuna campagna",
        campaign_id: "no",
        business_name: "",
        isActive: true,
      });
      setStores(
        Object.entries(
          campaigns.sort(byBusinessName).reduce(
            (acc, e) => ({
              ...acc,
              [e.business_owner_id]: e.business_owner_name,
            }),
            {}
          )
        )
      );
      setValues({ campaign: "no" /*campaigns[0].campaign_id*/ });
    }
    // eslint-disable-next-line
  }, [campaigns]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const handleSubmit = (fields, after) => {
    startLoading();
    const client_id = "16d4c11d-1798-4217-8834-d0fe37a4c49c";
    return bmapi
      .saveMessageCampaign(
        fields.title,
        fields.body,
        fields.banner,
        fields.push_notification,
        format(fields.expire_at, "yyyy-MM-dd"),
        fields.app_link_label,
        fields.app_link_uri,
        fields.image_url,
        fields.link_uri,
        values.campaign == "no" ? "" : values.campaign,
        client_id
      )
      .then(after)
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "pages.messageCampaign.create",
            defaultMessage: "Messaggio creato con successo",
          })
        );
        history.push(MANAGER_ROUTES.MESSAGES_CAMPAIGNS);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        //stopLoading();
      })
      .finally(stopLoading);
  };

  const handleChange = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  function CampaignsList({ campaigns }) {
    return (
      <Box>
        <TextField
          name="front_end_type"
          label="Campagna"
          value={values.campaign}
          onChange={handleChange("campaign")}
          select
          fullWidth
          required
          margin="normal"
        >
          {campaigns
            .filter((s) => s.isActive)
            .map((option) => (
              <MenuItem key={option.name} value={option.campaign_id}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
    );
  }

  return !businessId ? (
    <Container maxWidth="md" className="tablistNone"></Container>
  ) : campaigns ? (
    <Container maxWidth="sm" className="tablistNone">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="pages.campaigns.create"
              defaultMessage="Creazione Messaggio"
            />
          </Box>

          <Menu
            anchorEl={anchorEl}
            keepMounted={false}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => selectStore(0)}>
              <FormattedMessage
                id="common.allStores"
                defaultMessage="Tutti i negozi"
              />
            </MenuItem>
            {stores?.map((option) => (
              <MenuItem
                key={option[0]}
                onClick={() => selectStore(option[0])}
                selected={option[0] === currentStore}
              >
                {option[1]}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Title>
      <FormSection>
        <CampaignsList campaigns={campaigns} />
      </FormSection>
      <MessageCampaingCreateForm
        handleSubmit={handleSubmit}
        style={{ background: "red" }}
      />
    </Container>
  ) : null;
}
