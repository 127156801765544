import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink, useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Visibility, VisibilityOff } from "@material-ui/icons";

import LoginCard from "./LoginCard";
import { propagateUrlTracing, getQueryVariable } from "../utils/utils";
import { URL_TRACE_PARAM, URL_TRACE_VALUES } from "../utils/constants";
import { useIntl } from "react-intl";

export default function SignInForm({ onSubmit, routes }) {
  const intl = useIntl();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    const iSubtitle =
      getQueryVariable(location.search, URL_TRACE_PARAM) ===
      URL_TRACE_VALUES.SUBSCRIPTION
        ? intl.formatMessage({
            id: "pages.subscriptionStartLoginEmail.title",
            defaultMessage:
              "Per proseguire nell'acquisto accedi al tuo account o registrati",
          })
        : "";
    setSubtitle(iSubtitle);
  }, [intl]);

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(
      event.target.email.value.trim().toLowerCase(),
      event.target.password.value
    );
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  const backButton = (
    <Button
      onClick={() => history.push(routes.SIGN_IN + propagateUrlTracing())}
      startIcon={<ArrowBack />}
      color="inherit"
    >
      <FormattedMessage id="common.back" defaultMessage="Indietro" />
    </Button>
  );

  return (
    <LoginCard back={backButton} subtitle={subtitle}>
      {subtitle && <Box style={{ paddingTop: 50 }}></Box>}
      <Typography>
        <FormattedMessage
          id="component.signin.loginText"
          defaultMessage="Se sei già registrato inserisci le credenziali del tuo account"
        />
      </Typography>

      <form onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">
            <FormattedMessage id="common.email" defaultMessage="Email" />
          </InputLabel>
          <Input id="email" name="email" autoComplete="email" type="email" />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">
            <FormattedMessage id="common.password" defaultMessage="Password" />
          </InputLabel>
          <Input
            name="password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button type="submit" fullWidth variant="contained" color="primary">
            <FormattedMessage
              id="component.signin.title"
              defaultMessage="Accedi"
            />
          </Button>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Link
            component={RouterLink}
            to={routes.PASSWORD_FORGET + propagateUrlTracing()}
          >
            <FormattedMessage
              id="component.signin.forgotPassword"
              defaultMessage="Se hai dimenticato la password clicca qui per reimpostare le nuove credenziali"
            />
          </Link>
        </FormControl>
        <Box mt={6}>
          <Divider variant="middle" />
        </Box>
        <FormControl margin="normal" fullWidth>
          <Typography>
            <FormattedMessage
              id="component.signin.registerText"
              defaultMessage="Se non sei in possesso di un account, registrati!"
            />
          </Typography>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => history.push(routes.SIGN_UP + propagateUrlTracing())}
          >
            <FormattedMessage
              id="component.signin.register"
              defaultMessage="Registrati"
            />
          </Button>
        </FormControl>
      </form>
    </LoginCard>
  );
}
