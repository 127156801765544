import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { format, parse } from "date-fns";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import EOrderClosingDates from "../../components/EOrderClosingDates";
import {
  Add,
  Create,
  Delete,
  ErrorOutline,
  ExpandMore,
  FileCopy,
  Info,
  MoreVert,
} from "@material-ui/icons";
import FloatingActions from "../../ui/FloatingActions";
import { account, common, errors, confirm } from "../../messages";
import { TimePicker } from "../../ui/DatePicker";
import Title from "../../ui/Title";
import Confirm from "../../ui/Confirm";

const days = [
  { value: "MON", label: "Lunedì" },
  { value: "TUE", label: "Martedì" },
  { value: "WED", label: "Mercoledì" },
  { value: "THU", label: "Giovedì" },
  { value: "FRI", label: "Venerdì" },
  { value: "SAT", label: "Sabato" },
  { value: "SUN", label: "Domenica" },
];

const duration = [
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 60, label: "60" },
];

export default function OrderConfiguration() {
  useEffect(() => {
    loadConfig();
  }, [bmapi, loadConfig]);

  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();

  const emptyMapObj = (day) => {
    return {
      id: "",
      day: day,
      inactive: false,
      time_slot_value: "",
      time_slots: [
        {
          end: null,
          start: null,
          production_capacity: "",
          over_production: "",
        },
      ],
    };
  };

  const getEmptyConfMap = () => {
    let confMap = new Map();
    confMap.set("MON", emptyMapObj("MON"));
    confMap.set("TUE", emptyMapObj("TUE"));
    confMap.set("WED", emptyMapObj("WED"));
    confMap.set("THU", emptyMapObj("THU"));
    confMap.set("FRI", emptyMapObj("FRI"));
    confMap.set("SAT", emptyMapObj("SAT"));
    confMap.set("SUN", emptyMapObj("SUN"));
    return confMap;
  };

  const [confMap, setConfMap] = useState(getEmptyConfMap());
  const [filter, setFilter] = useState("closingDates");
  const [openDialog, setOpenDialog] = useState(false);
  const [openCheckDaysDialog, setOpenCheckDaysDialog] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [modify, setModify] = useState(false);
  const [copy, setCopy] = useState(false);
  const [confList, setConfList] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [checkedSlot, setCheckedSlot] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confSelected, setConfSelected] = React.useState(null);

  const [slotValue, setSlotValue] = useState(null);

  const initialValues = useMemo(
    () => ({
      end: null,
      start: null,
      production_capacity: "",
      over_production: "",
    }),
    []
  );

  const [values, setValues] = useState(initialValues);

  const initialStateValues = useMemo(
    () => ({
      MON: false,
      TUE: false,
      WED: false,
      THU: false,
      FRI: false,
      SAT: false,
      SUN: false,
    }),
    []
  );

  const [state, setState] = React.useState(initialStateValues);

  const getLabel = useCallback((code) => {
    const filtered = days.filter((obj) => obj.value === code);
    return filtered && filtered.length ? filtered[0].label : "";
  }, []);

  const getDayValue = () => {
    let days = [];
    for (const [key, value] of Object.entries(state)) {
      if (value) {
        days.push(key);
      }
    }
    return days;
  };

  const isDisabled = (c) => {
    if (modify && confSelected?.time_slots[0].id === c.id) {
      return false;
    } else return true;
  };

  const handleValue = useCallback(
    (label) => (e) => {
      if (label === "start" || label === "end") {
        ((val) => setValues((v) => ({ ...v, [label]: val })))(e);
      } else {
        ((val) => setValues((v) => ({ ...v, [label]: val })))(+e.target.value);
      }
    },
    []
  );

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSelectConf = (e, i, element) => {
    setAnchorEl(e.currentTarget);
    setConfSelected({ ...element, time_slots: [element.time_slots[i]] });
  };

  const handleOverProduction = (v) => {
    if (v.over_production) {
      return v.over_production;
    } else if (v.time_slots) {
      return v.time_slots[0].over_production + 100;
    } else return 0;
  };

  const getConf = (v) => {
    let arr = [];
    for (let i = 0; i < v.day.length; i++) {
      let newConf = { ...confMap.get(v.day[i]) };
      if (!newConf.id) {
        newConf.time_slot_value = slotValue;
        newConf.time_slots[0].start =
          v.start || format(v.time_slots[0].start, "HHmm");
        newConf.time_slots[0].end =
          v.end || format(v.time_slots[0].end, "HHmm");
        newConf.time_slots[0].production_capacity =
          v.production_capacity || v.time_slots[0].production_capacity;
        newConf.time_slots[0].over_production = handleOverProduction(v);
        arr.push(newConf);
      } else {
        newConf.time_slots.push({ ...v, day: v.day[i] });
        arr.push(newConf);
      }
    }
    return arr;
  };

  const checkTimeRange = (start, end) => {
    let time_start = start;
    let time_end = end;
    const value_start = format(time_start, "HH:mm:ss").split(":");
    const value_end = format(time_end, "HH:mm:ss").split(":");
    time_start.setHours(value_start[0], value_start[1], value_start[2], 0);
    time_end.setHours(value_end[0], value_end[1], value_end[2], 0);
    const minutes = (time_end - time_start) / 60000;
    if (minutes % slotValue === 0) {
      setCheckedSlot(true);
      return true;
    } else {
      setCheckedSlot(false);
      return false;
    }
  };

  const canDuplicate = (conf) => {
    const newSlotId = conf.time_slots[0].id.substring(3);
    const arr = Array.from(confMap.values());
    const options = Array.from(confMap.values());
    for (let i = arr.length - 1; i >= 0; i--) {
      for (let y = 0; y < arr[i].time_slots.length; y++) {
        if (arr[i].time_slots[y].id?.substring(3) === newSlotId) {
          const index = arr.indexOf(arr[i]);
          if (index > -1) {
            // only splice array when item is found
            options.splice(index, 1);
          }
        }
      }
    }
    let obj = {};
    for (let i = 0; i < options.length; i++) {
      const prop = options[i].day;
      obj[prop] = false;
    }
    setState(obj);
  };

  const confirmConfig = () => {
    const areFalse = Object.values(state).every((value) => value === false);
    if (checkedSlot && !areFalse) {
      let newValues = {
        ...values,
        start: format(values.start, "HHmm"),
        end: format(values.end, "HHmm"),
        over_production: values.over_production
          ? values.over_production + 100
          : 0,
        day: getDayValue(),
      };
      const configurations = getConf(newValues);
      const confToCreate = configurations.filter((conf) => !conf.id);
      const confToUpdate = configurations.filter((conf) => conf.id);
      const timeSlotToFormat = confToUpdate
        .map((conf) => conf.time_slots.filter((slot) => !slot.id))
        .flat();
      for (let i = 0; i < timeSlotToFormat.length; i++) {
        timeSlotToFormat[i].start = fixHour(timeSlotToFormat[i].start);
        timeSlotToFormat[i].end = fixHour(timeSlotToFormat[i].end);
        timeSlotToFormat[i].over_production =
          timeSlotToFormat[i].over_production - 100;
      }
      confToUpdate.map((conf) => updateDailyConf(conf, "modify"));
      if (confToCreate.length != 0) {
        bmapi
          .createEcomConf({ confs: confToCreate })
          .then(() => {
            notifySuccess(intl.formatMessage(account.saveConfirm));
            setOpenCheckDaysDialog(false);
            setOpenDialog(false);
            setConfSelected(null);
            setValues(initialValues);
          })
          .then(() => loadConfig())
          .catch((e) => {
            if (
              getErrorMessageString(e, intl) ===
              "start time cannot be equal or greater than end time"
            ) {
              notifyError(intl.formatMessage(errors.equalTime));
              setOpenCheckDaysDialog(false);
              setValues(initialValues);
              loadConfig();
            } else if (
              getErrorMessageString(e, intl) ===
              "time slot overlaps with another time slot"
            ) {
              notifyError(intl.formatMessage(errors.slotOverlaps));
              setOpenCheckDaysDialog(false);
              setValues(initialValues);
              loadConfig();
            } else {
              notifyError(getErrorMessageString(e, intl));
            }
          });
      }
    } else if (!checkedSlot && !areFalse) {
      notifyError(
        "Si è verificato un errore. Controlla che gli orari scelti siano compatibili con la durata dello slot."
      );
    } else if (checkedSlot && areFalse) {
      notifyError("È obbligatorio indicare almeno un giorno");
    } else if (!checkedSlot && areFalse) {
      notifyError(
        "Si è verificato un errore. Controlla che gli orari scelti siano compatibili con la durata dello slot."
      );
    }
  };

  const duplicate = (conf) => {
    let newValues = {
      ...conf,
      day: getDayValue(),
    };
    const configurations = getConf(newValues);
    const confToCreate = configurations.filter((conf) => !conf.id);
    const timeSlotToFormat = confToCreate
      .map((conf) => conf.time_slots.filter((slot) => !slot.id))
      .flat();
    for (let i = 0; i < timeSlotToFormat.length; i++) {
      timeSlotToFormat[i].start = format(timeSlotToFormat[i].start, "HHmm");
      timeSlotToFormat[i].end = format(timeSlotToFormat[i].end, "HHmm");
      timeSlotToFormat[i].over_production =
        timeSlotToFormat[i].over_production + 100;
    }
    const confToUpdate = configurations.filter((conf) => conf.id);
    confToUpdate.map((conf) => updateDailyConf(conf, "duplicate"));
    if (confToCreate.length != 0) {
      bmapi
        .createEcomConf({ confs: confToCreate })
        .then(() => {
          notifySuccess(intl.formatMessage(account.saveConfirm));
          setOpenCheckDaysDialog(false);
          setOpenDialog(false);
          setConfSelected(null);
          setCopy(false);
        })
        .then(() => loadConfig())
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  const fixHour = (time) => {
    const newDate = format(new Date(), "dd/MM/yyyy");
    const newTime = time.substring(0, 2) + ":" + time.substring(2, 4);
    const dateTime = newDate + " " + newTime;
    return parse(dateTime, "dd/MM/yyyy HH:mm", new Date());
  };

  const dateFormat = useCallback(
    (day) => {
      let conf = confMap.get(day);
      if (conf.id) {
        for (let i = 0; i < conf.time_slots.length; i++) {
          conf.time_slots[i]["start"] = fixHour(conf.time_slots[i].start);
          conf.time_slots[i]["end"] = fixHour(conf.time_slots[i].end);
        }
        setConfMap(new Map(confMap.set(day, conf)));
      }
    },
    [confMap]
  );

  const handleTimeSlotValue = useCallback(
    (day, value) => {
      let conf = confMap.get(day);
      if (!conf.id) {
        conf.time_slot_value = value;
        setConfMap(new Map(confMap.set(day, conf)));
      }
    },
    [confMap]
  );

  const handleTimeSlot = useCallback(
    (label, index, day) => (e) => {
      let conf = confMap.get(day);
      if (label === "end" || label === "start")
        conf.time_slots[index][label] = e;
      else {
        conf.time_slots[index][label] = e.target.value;
      }
      setConfMap(new Map(confMap.set(day, conf)));
    },
    [confMap]
  );

  const loadConfig = useCallback(() => {
    if (bmapi) {
      bmapi
        .getEcomConf({
          business: bmapi.getUserInfo().business.id,
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
            setConfList(false);
            setSlotValue(null);
          } else {
            if (resp.length > 0) setConfList(true);
            // check if time_slots is null
            for (let i = 0; i < resp.length; i++) {
              if (resp[i].time_slots === null) {
                const objIndex = resp.findIndex(
                  (obj) => obj.time_slots === null
                );
                resp[objIndex].time_slots = [
                  {
                    end: null,
                    start: null,
                  },
                ];
              }
            }
            //
            const rTimeSlots = resp.map((r) => r.time_slots).flat();
            for (let i = 0; i < rTimeSlots.length; i++) {
              rTimeSlots[i].over_production = rTimeSlots[i].over_production
                ? rTimeSlots[i].over_production - 100
                : 0;
            }
            const rConf = resp.map((r) => confMap.get(r.day));
            for (let i = 0; i < rConf.length; i++) {
              const objMap = resp.find((r) => r.day === rConf[i].day);
              setConfMap(new Map(confMap.set(objMap.day, objMap)));
            }
            confMap.forEach((v) => dateFormat(v.day));
            const hasTimeSlotValue = resp[0].time_slot_value;
            confMap.forEach((v) =>
              handleTimeSlotValue(v.day, hasTimeSlotValue)
            );
            setSlotValue(hasTimeSlotValue);
            setState(initialStateValues);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [
    bmapi,
    intl,
    notifyError,
    confMap,
    dateFormat,
    handleTimeSlotValue,
    initialStateValues,
  ]);

  const createObj = (o) => {
    return Object.assign(o, {
      start: format(o.start, "HHmm") || format(o.time_slots[0].start, "HHmm"),
      end: format(o.end, "HHmm") || format(o.time_slots[0].end, "HHmm"),
      over_production:
        +o.over_production + 100 || +o.time_slots[0].over_production + 100,
      production_capacity:
        +o.production_capacity || +o.time_slots[0].production_capacity,
    });
  };

  const updateDailyConf = useCallback(
    (conf, label) => {
      const newDailyConf = confMap.get(conf.day);
      newDailyConf.time_slots.map((o) => createObj(o));
      bmapi
        .updateEcomConf(newDailyConf)
        .then(() => {
          if (label === "modify") {
            notifySuccess(intl.formatMessage(account.saveConfirm));
            setModify(false);
            setConfSelected(null);
            setOpenCheckDaysDialog(false);
            setOpenDialog(false);
            setValues(initialValues);
          } else if (label === "delete") {
            notifySuccess(intl.formatMessage(common.deleteElement));
            setConfSelected(null);
          } else if (label === "duplicate") {
            setOpenCheckDaysDialog(false);
            setOpenDialog(false);
            setConfSelected(null);
            setCopy(false);
          }
        })
        .then(() => loadConfig())
        .catch((e) => {
          if (
            getErrorMessageString(e, intl) ===
            "start time cannot be equal or greater than end time"
          ) {
            notifyError(intl.formatMessage(errors.equalTime));
            setOpenCheckDaysDialog(false);
            setValues(initialValues);
            loadConfig();
          } else if (
            getErrorMessageString(e, intl) ===
            "time slot overlaps with another time slot"
          ) {
            notifyError(intl.formatMessage(errors.slotOverlaps));
            setOpenCheckDaysDialog(false);
            setValues(initialValues);
            loadConfig();
          } else {
            notifyError(getErrorMessageString(e, intl));
          }
        });
    },
    [
      bmapi,
      confMap,
      initialValues,
      intl,
      loadConfig,
      notifyError,
      notifySuccess,
    ]
  );

  const deleteDailyConf = useCallback(
    (conf) => {
      let dailyConf = confMap.get(conf.day);
      if (dailyConf.time_slots.length === 1) {
        bmapi
          .deleteEcomConf(conf.id)
          .then(() => {
            notifySuccess(intl.formatMessage(common.deleteElement));
            setConfMap(new Map(confMap.set(conf.day, emptyMapObj(conf.day))));
            setConfSelected(null);
            loadConfig();
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          });
      } else {
        for (let i = 0; i < dailyConf.time_slots.length; i++) {
          if (conf.time_slots[0].id === dailyConf.time_slots[i].id) {
            const index = dailyConf.time_slots.indexOf(dailyConf.time_slots[i]);
            if (index > -1) {
              // only splice array when item is found
              dailyConf.time_slots.splice(index, 1);
            }
          }
        }
        updateDailyConf(dailyConf, "delete");
      }
    },
    [
      bmapi,
      confMap,
      intl,
      loadConfig,
      notifyError,
      notifySuccess,
      updateDailyConf,
    ]
  );

  const reset = () => {
    loadConfig();
    setOpenDialog(false);
    setValues(initialValues);
    let check = Array.from(confMap.values()).find(
      (conf) => conf.time_slot_value != ""
    );
    if (check === undefined || !check.time_slot_value) {
      setSlotValue(null);
    }
  };

  const isCompiled = (v) => {
    if (v.start && v.end) {
      const checked = checkTimeRange(v.start, v.end);
      if (!checked && slotValue) {
        return notifyError(
          "Si è verificato un errore. Controlla che gli orari scelti siano compatibili con la durata dello slot."
        );
      }
    }
    if (!v.end || !v.start || !slotValue) {
      return notifyError("I campi contrassegnati con * sono obbligatori");
    } else if (!v.production_capacity) {
      return notifyError("Il numero di ordini non può essere 0");
    } else {
      return setOpenCheckDaysDialog(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Title>Configurazioni</Title>
      <Tabs
        value={filter}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        onChange={(_, v) => setFilter(v)}
      >
        <Tab
          label="Giorni di chiusura"
          value="closingDates"
          style={{ minWidth: 0 }}
        />
        <Tab
          label="Conf. giornaliera"
          value="configuration"
          style={{ minWidth: 0 }}
        />
      </Tabs>
      {filter === "closingDates" && <EOrderClosingDates filter={filter} />}

      {filter === "configuration" && (
        <>
          {confList ? (
            <Box mt={2}>
              <FormControl margin="normal">
                <FormLabel>Durata slot: {slotValue + " minuti"}</FormLabel>
              </FormControl>
              <IconButton onClick={() => setOpenInfo(true)}>
                <Info />
              </IconButton>
              {Array.from(confMap.values())
                .filter((conf) => conf.id)
                .map((conf) => (
                  <Accordion key={conf.day}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      style={{ flexDirection: "row-reverse" }}
                      disableRipple
                    >
                      {days
                        .filter((day) => day.value === conf.day)
                        .map((day) => (
                          <Typography key={day.value}>{day.label}</Typography>
                        ))}
                    </AccordionSummary>
                    {conf.time_slots.map((c, i) => (
                      <React.Fragment key={c.id}>
                        <AccordionDetails style={{ padding: "0 16px" }}>
                          <Grid container spacing={3}>
                            <Grid item xs={5}>
                              <TimePicker
                                disabled={isDisabled(c)}
                                style={{ marginTop: 0 }}
                                label={intl.formatMessage(common.from)}
                                inputFormat="HH:mm"
                                value={c.start}
                                onChange={handleTimeSlot("start", i, conf.day)}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TimePicker
                                disabled={isDisabled(c)}
                                style={{ marginTop: 0 }}
                                label={intl.formatMessage(common.to)}
                                inputFormat="HH:mm"
                                value={c.end}
                                onChange={handleTimeSlot("end", i, conf.day)}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                onClick={(e) => {
                                  handleSelectConf(e, i, conf);
                                }}
                              >
                                <MoreVert />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                        <AccordionDetails style={{ padding: "0px 16px" }}>
                          <TextField
                            disabled={isDisabled(c)}
                            value={c.production_capacity}
                            label="Numero di ordini"
                            InputLabelProps={{
                              shrink: c.production_capacity ? true : false,
                            }}
                            fullWidth
                            onChange={handleTimeSlot(
                              "production_capacity",
                              i,
                              conf.day
                            )}
                            type="number"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                            }}
                          />
                        </AccordionDetails>
                        <AccordionDetails
                          style={{ padding: "0px 16px", marginBottom: 20 }}
                        >
                          <TextField
                            disabled={isDisabled(c)}
                            value={c.over_production}
                            label="% sovrapproduzione sull'ultimo slot"
                            InputLabelProps={{
                              shrink:
                                c.over_production || c.over_production === 0
                                  ? true
                                  : false,
                            }}
                            fullWidth
                            onChange={handleTimeSlot(
                              "over_production",
                              i,
                              conf.day
                            )}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </AccordionDetails>
                        {!isDisabled(c) && (
                          <AccordionDetails
                            style={{ padding: "0px 16px", marginBottom: 20 }}
                          >
                            <Button
                              onClick={() => {
                                setModify(false);
                                loadConfig();
                              }}
                            >
                              {intl.formatMessage(common.cancel)}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                updateDailyConf(confSelected, "modify")
                              }
                            >
                              {intl.formatMessage(common.update)}
                            </Button>
                          </AccordionDetails>
                        )}
                      </React.Fragment>
                    ))}
                  </Accordion>
                ))}
            </Box>
          ) : (
            <Box mt={2}>
              <Card mt={2}>
                <CardContent>Nessuna configurazione presente.</CardContent>
              </Card>
            </Box>
          )}

          <Dialog
            open={openDialog}
            onClose={() => reset()}
            fullWidth
            maxWidth="sm"
            style={{ margin: "-15px" }}
          >
            <DialogTitle>Aggiungi configurazione</DialogTitle>
            <DialogContent style={{ padding: "0 15px" }}>
              {!confList && (
                <FormControl margin="dense" required>
                  <FormLabel>Durata slot (minuti)</FormLabel>
                  <RadioGroup
                    row
                    name="MapType"
                    value={slotValue}
                    onChange={(e) => setSlotValue(+e.target.value)}
                  >
                    {duration.map((d) => (
                      <FormControlLabel
                        key={d.value}
                        value={d.value}
                        control={
                          <Radio required size="small" color="primary" />
                        }
                        label={d.label}
                      />
                    ))}
                  </RadioGroup>
                  <Grid container spacing={0}>
                    <Grid item xs={1}>
                      <ErrorOutline />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        La durata slot è uguale per tutte le configurazioni
                        giornaliere. Una volta impostata, sarà possibile
                        modificarla solo dopo aver eliminato le configurazioni
                        esistenti.
                      </Typography>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <TimePicker
                    style={{ marginTop: 0 }}
                    label={intl.formatMessage(common.from)}
                    inputFormat="HH:mm"
                    value={values.start}
                    onChange={handleValue("start")}
                    renderInput={(params) => <TextField {...params} />}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    style={{ marginTop: 0 }}
                    label={intl.formatMessage(common.to)}
                    inputFormat="HH:mm"
                    value={values.end}
                    onChange={handleValue("end")}
                    renderInput={(params) => <TextField {...params} />}
                    required
                  />
                </Grid>
              </Grid>
              <TextField
                value={values.production_capacity}
                label="Numero di ordini"
                fullWidth
                onChange={handleValue("production_capacity")}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
                margin="dense"
                required
              />
              <TextField
                value={values.over_production}
                label="% sovrapproduzione sull'ultimo slot"
                fullWidth
                onChange={handleValue("over_production")}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset()}>
                {intl.formatMessage(common.cancel)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => isCompiled(values)}
              >
                Continua
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openCheckDaysDialog}
            onClose={() => {
              setOpenCheckDaysDialog(false);
              setState(initialStateValues);
              loadConfig();
            }}
            fullWidth
            maxWidth="sm"
            style={{ margin: "-15px" }}
          >
            <DialogTitle>
              Indica i giorni per i quali vuoi impostare la configurazione
            </DialogTitle>
            <DialogContent style={{ padding: "0 15px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {Object.keys(state).map((key, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={state.key}
                          onChange={handleChange}
                          name={key}
                          color="primary"
                        />
                      }
                      label={getLabel(key)}
                    />
                  );
                })}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenCheckDaysDialog(false);
                  setState(initialStateValues);
                }}
              >
                {intl.formatMessage(common.back)}
              </Button>
              {!copy ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={confirmConfig}
                >
                  {intl.formatMessage(common.create)}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    duplicate(confSelected?.time_slots[0]);
                  }}
                >
                  {intl.formatMessage(common.confirm)}
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            open={openInfo}
            onClose={() => setOpenInfo(false)}
            maxWidth={"xs"}
          >
            <DialogContent>
              <Typography>
                La durata slot è uguale per tutte le configurazioni giornaliere.
                Una volta impostata, sarà possibile modificarla solo dopo aver
                eliminato le configurazioni esistenti.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenInfo(false)} variant="contained">
                {intl.formatMessage(common.close)}
              </Button>
            </DialogActions>
          </Dialog>

          <Menu
            key={confSelected}
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={() => {
              setAnchorEl(null);
              setConfSelected(null);
            }}
            onClick={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setModify(true);
              }}
            >
              <ListItemIcon>
                <Create fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">
                {intl.formatMessage(common.modify)}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenCheckDaysDialog(true);
                canDuplicate(confSelected);
                setCopy(true);
              }}
            >
              <ListItemIcon>
                <FileCopy fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Duplica</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowDeleteAlert(true);
              }}
            >
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">
                {intl.formatMessage(common.delete)}
              </Typography>
            </MenuItem>
          </Menu>

          <Confirm
            open={showDeleteAlert}
            onConfirm={() => {
              deleteDailyConf(confSelected), setShowDeleteAlert(false);
            }}
            onCancel={() => {
              setConfSelected(null);
              setShowDeleteAlert(false);
            }}
            text={intl.formatMessage(confirm.deleteElement)}
          />

          <FloatingActions>
            <Fab
              variant="extended"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              <Add />
              Aggiungi configurazione
            </Fab>
          </FloatingActions>
        </>
      )}
    </Container>
  );
}
